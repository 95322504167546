import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _curry3 from "lodash/curry";

var _curry2 = "default" in _curry3 ? _curry3.default : _curry3;

import * as _classnames3 from "classnames";

var _classnames2 = "default" in _classnames3 ? _classnames3.default : _classnames3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.prefix = exports.defaultClassPrefix = exports.getClassNamePrefix = exports.globalKey = void 0;

var _curry = _interopRequireDefault(_curry2);

var _classnames = _interopRequireDefault(_classnames2);

var globalKey = "rs-";
exports.globalKey = globalKey;

var getClassNamePrefix = function getClassNamePrefix() {
  return globalKey;
};

exports.getClassNamePrefix = getClassNamePrefix;

var defaultClassPrefix = function defaultClassPrefix(name) {
  return name ? "" + getClassNamePrefix() + name : undefined;
};

exports.defaultClassPrefix = defaultClassPrefix;
var prefix = (0, _curry["default"])(function (pre, className) {
  if (!pre || !className) {
    return "";
  }

  if (Array.isArray(className)) {
    return (0, _classnames["default"])(className.filter(function (name) {
      return !!name;
    }).map(function (name) {
      return pre + "-" + name;
    }));
  }

  return pre + "-" + className;
});
exports.prefix = prefix;
export default exports;