import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _prefix2 from "./prefix";
import * as _classnames3 from "classnames";

var _classnames2 = "default" in _classnames3 ? _classnames3.default : _classnames3;

import * as _react3 from "react";

var _react2 = "default" in _react3 ? _react3.default : _react3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports["default"] = useClassNames;
var _prefix = _prefix2;

var _classnames = _interopRequireDefault(_classnames2);

var _react = _react2;

function useClassNames(componentClassName) {
  var className = (0, _prefix.defaultClassPrefix)("icon");
  var addPrefix = (0, _react.useCallback)(function (blockClassName) {
    return (0, _prefix.prefix)(className, blockClassName);
  }, []);
  return [(0, _classnames["default"])(className, (0, _prefix.defaultClassPrefix)(componentClassName)), addPrefix];
}

exports = exports.default;
export default exports;